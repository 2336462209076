import React from "react";
import "./Sunshine.css";
import me from "../assets/sunset.gif"


const Sunshine = () => {

return (
    <div className="s-div">
        <img src={me} alt="lookaway"/>
        <div className="s-text">
            <span className="s-title">Sunshine</span>
            <p> Sunshine that brings light and happiness to my life.</p>
            <p> The sunshine that gave my existence and decisions meaning.</p>
            <p> The sunshine that reminds me to cherish the light when it embraces me.</p>
            <p> The sunshine that will now turn into a sunset.</p>
            <p> The sunset that I've always felt sad to look at because I know the light will be gone soon.</p>
            <p> The sunset that reminds me that the light will be anywhere else but here with me.</p>
            <p> Sunshine that will shine in my heart no matter how dark it gets.</p>
            <span className="s-title">You are..</span>
            <p> Mi pequeño Sol.</p>
            <p> My lil’ Solanum tuberosum.</p>
            <p> My sunshine</p>
        </div>
    </div>
)
};

export default Sunshine

